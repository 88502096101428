
export default {
    name: "LabeledTextField",

    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        },
        onfocus: {
            type: Function,
            default: () => {}
        },
        readonly: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        validateOnBlur: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        }
    },

}
